import React, { useState, useEffect } from 'react';
import { loadScript } from '@paypal/paypal-js';

const SubscribeButton = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    (async () => {
      let paypal;
      let clientId = 'AcwYd6s7PvOijpGWS_voeXwfOIGmXqwim_33wcymNG31vWIPqUqCk8hVHvsvdOweId-5YUBYoUac0DjN';

      try {
        paypal = await loadScript({
          'client-id': clientId,
          'vault': true
        });
      } catch (error) {
        console.error("failed to load the PayPal JS SDK script", error);
      }
  
      if (!loaded && paypal) {
        try {
          if (paypal.Buttons) {
            await paypal.Buttons({
              style: {
                  shape: 'rect',
                  color: 'gold',
                  layout: 'vertical',
                  label: 'subscribe'
              },
              createSubscription: function(data, actions) {
                return actions.subscription.create({
                  /* Creates the subscription */
                  plan_id: 'P-41W77043FG543125CMGNBS3Q'
                });
              },
              onApprove: function(data, actions) {
                alert(data.subscriptionID); // You can add optional success message for the subscriber here
                return Promise.resolve();
              }
            }).render("#SubscribeButton");
            setLoaded(true)
          }
        } catch (error) {
          console.error("failed to render the PayPal Buttons", error);
        }
      }
    })()
  });

  return <div id="SubscribeButton"></div>;
}

export default SubscribeButton;
